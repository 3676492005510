.pagination {
  .back-button,
  .next-button {
    padding: 0 20px;
    vertical-align: middle;
    display: inline-block;
    &.disabled {
      opacity: .4;
    }
    img {
      float: left;
    }
  }
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
  }
  .back-button img {
    transform: scaleX(-1);
  }
  .next-button {
    padding-right: 0;
  }
  .back-button {
    padding-left: 0;
  }
}