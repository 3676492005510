.grants-page {
  .download-section {
    padding: 9rem 0;
    .tile {
      margin: 30px;
      max-width: 320px;
    }
  }
  .submit-btn {
    margin-top: 46px;
  }

  .form {
    max-width: 600px;
    margin: 8rem auto;
  }
}