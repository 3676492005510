.section-title {
  line-height: 1;
  padding: 0 17px;
  margin-left: -17px;
  white-space: pre-wrap;
  font-size: 40px;
  background-position: 0 34px;
  background-repeat: repeat-x;
  background-size: 100% 30px;

  &.orange-yellow {
    background-image: linear-gradient(94deg, #ffd52d, #ffab14);
  }

  &.green {
    background-image: linear-gradient(95deg, #83d1bb, #4ca585);
  }

  &.light-yellow {
    background-image: linear-gradient(94deg, #f9f7ab, #f0ec73);
  }

  &.light-green {
    background-image: linear-gradient(97deg, #d5efc4, #aada91);
  }
}

.grey-bg {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.56;
    background-image: linear-gradient(to top, #ffffff, #e5eef1);
  }
}

