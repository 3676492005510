.blog-post {
  max-width: 864px;
  margin: 40px auto 0;
  box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);


  .img-wrapper {
    padding-bottom: 40%;
  }

  .social-block {
    a {
      margin-left: 24px;
    }

    a,
    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  .date {
    position: absolute;
    top: 40px;
    right: 32px;
    font-size: 20px;
    @include max-screen(768px) {
      position: relative;
      right: 0;
      top: 0;
    }
    .bigger {
      font-size: 28px;
    }
  }

  .text-block {
    padding: 28px 32px 34px;
    position: relative;
    h3 {
      margin-top: 4px;
      width: calc(100% - 150px);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      margin-top: 12px;
    }
  }

  .table {
    width: 100%;
    margin-top: 15px;
    display: table;
    @include max-screen(768px) {
      display: block;
    }
    .table-cell {
      display: table-cell;
      vertical-align: middle;
      @include max-screen(768px) {
        display: block;
        text-align: center;
      }
    }
  }
}

.blog-page-inside-post {
  position: relative;

  .max-width {
    position: relative;
    max-width: 720px;
    margin: 8rem auto;
    @include max-screen(1024px) {
      margin: 4rem auto;
    }
  }

  .social-block {
    margin-top: 4rem;
    a {
      margin-left: 24px;
    }

    a,
    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  p + p,
  .img-container + p,
  .img-container {
    margin-top: 20px;
  }

  .img-caption {
    display: block;
    margin-top: 8px;
    opacity: 0.8;
    text-align: center;
  }

  .arr-left,
  .arr-right {
    position: absolute;
    z-index: 2;
    top: 10%;
    width: 83px;
    height: 83px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
    display: inline-block;
    border-radius: 50%;
    &:after {
      content: "";
      border: solid #4ca585;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: rotate(135deg) translateY(50%);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
    }
    @include max-screen(1024px) {
      position: relative;
      display: inline-block;
      top: 0;
      margin-top: 20px;
      margin-right: 40px;
      width: 60px;
      height: 60px;
    }
  }

  .arr-left {
    left: 60px;
    @include max-screen(1199px) {
      left: 10px;
    }
    @include max-screen(1024px) {
      left: 0;
      margin-left: 20px;
    }
  }

  .arr-right {
    right: 60px;
    &:after {
      transform: rotate(-45deg) translateY(-50%);
    }
    @include max-screen(1199px) {
      right: 10px;
    }
    @include max-screen(1024px) {
      right: 0;
    }
  }

  .indicators {
    margin-top: 20px;
  }

  .back {
    z-index: 2;
    position: absolute;
    left: 60px;
    top: 0;
    @include max-screen(1199px) {
      left: 10px;
    }
    @include max-screen(1024px) {
      display: block;
      left: 0;
      position: relative;
      margin-top: 40px;
      padding-left: 20px;
    }
    span {
      vertical-align: middle;
      color: $dark-grey-blue;
    }
    img {
      transform: rotate(180deg);
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}

.indicators {
  margin-right: 50px;
  display: inline-block;
  @include max-screen(768px) {
    margin: 20px;
  }

  span,
  img {
    vertical-align: middle;
  }

  span {
    margin-left: 10px;
  }
}
