/*CUSTOM*/
/*------------------------------------------------------------------------------------------------------*/
.daterangepicker {
  padding: 10px 30px 10px;
  @include max-screen(1024px) {
    padding: 0;
  }
}
.daterangepicker .drp-selected {
  display: none;
}
.daterangepicker .drp-buttons {
  border-top: 0;
}
.daterangepicker .drp-calendar.left {
  @include max-screen(1024px) {
    padding: 0;
  }
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  width: 37px;
  height: 37px;
  font-size: 16px;
  line-height: 36px;
  border-radius: 0;
  font-weight: 400;
  box-sizing: border-box;
  @include max-screen(1024px) {
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    min-width: 20px;
  }
}
.daterangepicker .calendar-table thead tr:nth-child(1) th{
  height: 45px;
  line-height: 45px;
}
.daterangepicker .calendar-table thead tr:nth-child(2){
  background-color: rgba(225, 227, 230, 0.16);
  th,
  td {
    font-size: 14px;
    color: rgb(179, 183, 190);
    padding: 4px 10px;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
  }
}
.daterangepicker .drp-calendar.right {
  padding-left: 40px;
  @include max-screen(1024px) {
    padding-left: 0;
    float: none;
  }
}
.daterangepicker td.start-date.end-date {
  border-radius: 0;
}

.daterangepicker .drp-buttons .btn {
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 86, 120, 0.8);
  box-shadow: none;
  padding: 10px;

  &.cancelBtn {
    color: rgba(37, 52, 75, 0.8);
    float: left;
    margin-left: 0;
  }
}

.daterangepicker th.month {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  color: rgb(112, 119, 133);
}

.datepicker {
  cursor: pointer;
  position: relative;
  margin-top: 12px;

  .right-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-30px, -50%);
    opacity: 0.6;
  }

  .output {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: table;
    width: 100%;
    height: 112px;

    .start-date,
    .end-date {
      user-select: none;
      width: 50%;
      display: table-cell;
      padding-right: 44px;
      box-sizing: border-box;
      vertical-align: middle;

      .date {
        display: inline-block;
        width: 100%;
        font-size: 60px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -2.3px;
        text-align: center;
        color: rgb(37, 52, 75);
        font-family: Arial;
      }

      .month {
        display: inline-block;
        width: 100%;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: -1px;
        text-align: center;
        color: rgb(37, 52, 75);
      }
    }

    .end-date {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid #ffab14;
        top: 50%;
        left: 100%;
        transform: translate(-34px, -50%);
      }

    }
  }

  input[name="dates"] {
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    color: transparent;
    background: transparent;
    height: 112px;
    border: solid 2px rgb(225, 227, 230);
  }
}