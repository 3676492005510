.plan-page {
  .events-wrapper {
    margin:  0 auto 9rem;
    text-align: center;

    .event-block {
      width: calc(33.3% - 50px);
      float: none;
      display: inline-block;
      @include max-screen(1024px) {
        width: calc(50% - 80px);
        margin: 7rem 35px 0;
        float: none;
        display: inline-block;
      }
      @include max-screen(768px) {
        margin: 4rem 20px 0;
        width: 320px;
      }
    }
  }
  .events-calendar-text {
    padding: 4.5rem 0 11rem;
  }

  .calendar-widget {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
  }
  .events-calendar-wrapper {
    .left-part {
      float: left;
      width: 280px;
      @include max-screen(1024px) {
        max-width: 375px;
        width: 100%;
        margin: 0 auto 8rem;
        float: none;
      }

      .custom-select {
        width: 100%;
        text-align: left;
      }
      .select-selected {
        padding: 5px 0;
      }
    }

    .right-part {
      width: calc(100% - 280px);
      padding-left: 60px;
      box-sizing: border-box;
      float: left;
      @include max-screen(1199px) {
        padding-left: 50px;
      }
      @include max-screen(1024px) {
        width: 100%;
        padding-left: 0;
      }
    }

    .events-calendar-block {
      margin-bottom: 5rem;
      @include max-screen(1024px) {
        width: 50%;
        margin: 0 auto 4rem;
      }
      @include max-screen(768px) {
        width: 100%;
        max-width: 375px;
      }
      .img-wrapper {
        width: 35%;
        padding-bottom: 25%;
        @include max-screen(1199px) {
          margin-top: 11px;
        }
        @include max-screen(1024px) {
          width: 100%;
          padding-bottom: 75%;
          margin-top: 17px;
        }
      }
      .text-wrapper {
        width: 65%;
        padding-left: 4rem;
        box-sizing: border-box;
        @include max-screen(1024px) {
          width: 100%;
          padding-left: 0;
        }

        h3 + span {
          margin-top: 8px;
          display: inline-block;
        }

        p {
          margin-top: 24px;
          @include max-screen(1199px) {
            margin-top: 11px;
          }
          @include max-screen(1024px) {
            margin-top: 20px;
          }
        }

        h3 {
          margin-top: 8px;
        }

      }
      .date-info {
        @include max-screen(1199px) {
          float: none;
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }
}