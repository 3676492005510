.agendas-page {
  .main-text-block {
    padding: 8rem 0;
  }
  .filter-wrapper {
    .container {
      max-width: 715px;
      margin: 20px auto;
      display: table;
      @include max-screen(1024px) {
        display: block;
        margin-top: 40px;
      }
      .table-cell {
        display: table-cell;
        vertical-align: middle;
        @include max-screen(1024px) {
          display: block;
          text-align: left;
          overflow: hidden;
        }
      }
      .year-container {
        margin: 0 20px;
        @include max-screen(1024px) {
          margin: 20px 0 0;
        }
        .form__input {
          margin-bottom: 0;
          margin-left: 40px;
          @include max-screen(1024px) {
            margin-left: 0;
          }
        }
      }
      .checkbox-wrapper {
        display: inline-block;
        padding-left: 22px;
        box-sizing: border-box;
        @include max-screen(1024px) {
          width: calc(100% - 70px);
          white-space: nowrap;
          overflow: scroll;
          vertical-align: middle;
        }
        .check-container {
          margin-left: 8px;
        }
      }
    }
  }

  .agendas-wrapper {
    margin-bottom: 10rem;
    @include max-screen(1024px) {
      margin-bottom: 6rem;
    }
  }

  .agenda {
    background-image: linear-gradient(138deg, rgb(255, 255, 255), rgb(219, 236, 243));
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
    padding-bottom: 110px;
    box-sizing: border-box;
    height: 263px;
    position: relative;
    text-align: center;
    padding-top: 68px;
    margin: 20px;
    width: calc(25% - 40px);
    float: left;
    @include max-screen(1024px) {
      width: calc(33% - 40px);
    }
    @include max-screen(768px) {
      width: calc(50% - 40px);
    }
    @include max-screen(480px) {
      width: calc(100% - 40px);
    }

    .btn-container {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .btn {
        float: left;
        width: 100%;
        margin-bottom: 4px;
      }
    }
  }
}