.form {
  margin: 3rem 0 12rem;

  .half-width {
    float: left;
    width: calc(50% - 10px);
    margin-right: 20px;
    &:nth-child(2) {
      margin-right: 0;
    }
    @include max-screen(768px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__input,
  &__textarea {
    width: 100%;
    font: inherit;
    font-size: 1.8rem;
    padding: 1.8rem 2rem;
    background-color: #ffffff;
    border: solid rgba(0, 86, 119, 0.4);
    border-width: 0 0 4px;
    margin-bottom: 2.4rem;
    position: relative;
    color: $dark-grey-blue;

    &:focus {
      outline-color: $color-orange;
    }
  }

  &__textarea {
    height: 6rem;
    margin-bottom: 3rem;

    @include max-screen(991px) {
      height: 22rem;
    }

    @include max-screen(767px) {
      height: 15rem;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include max-screen(767px) {
      text-align: center;
      .btn {
        margin: 0 auto;
      }
    }
  }

  &__info {
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    font-size: 1.4rem;
    color: $color-dark-grey;

    @include max-screen(991px) {
      margin-bottom: 2rem;
    }
  }
}
.search-input {
  max-width: 520px;
  position: relative;
  padding-left: 57px;
  &.search-ico {
    position: absolute;
  }
}