.contact-page {
  .contact-section {
    padding: 11rem 0 7rem;
    @include max-screen(1024px) {
      padding: 8rem 0 4rem;
    }

    .map,
    .form-section {
      width: 50%;
      box-sizing: border-box;
      float: left;
      @include max-screen(1024px) {
        float: none;
        max-width: 720px;
        margin: 0 auto;
        width: 100%;
      }
    }
    .form-section {
      padding-left: 40px;
      @include max-screen(1024px) {
        padding-left: 0;
        margin-top: 6rem;
      }
      textarea {
        height: 144px;
        box-sizing: border-box;
      }
    }

    .map {
      padding-right: 40px;
      @include max-screen(1024px) {
        padding-right: 0;
      }
      p {
        margin-top: 2.7rem;
      }

      #map {
        margin-top: 3.6rem;
        width: 100%;
        padding-bottom: 50%;
      }
      .left,
      .right {
        width: 50%;
        margin-top: 13px;
        float: left;
        @include max-screen(480px) {
          width: 100%;
        }
        * {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}