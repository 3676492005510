.slidedown-select {
  margin-top: 16px;
  & + .slidedown-select {
    margin-top: 6px;
  }
  .js-toggle-select {
    margin-bottom: 5px;
    position: relative;
    opacity: 0.8;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    user-select: none;
    &:after {
      position: absolute;
      content: "";
      top: 4px;
      right: 2px;
      border: solid black;
      border-width: 0 2px 2px 0;
      border-radius: 50% 1px 1px 1px;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
    &.active:after {
      transform: rotate(-135deg);
    }
  }
  ul {
    display: none;
    li {
      cursor: pointer;
      font-size: 16px;
      line-height: 2.08;
      opacity: 0.8;
      &.active {
        font-weight: 600;
      }
    }
  }
}