html {
  box-sizing: border-box;
  font-size: 62.5%;

  @include max-screen(767px) {
    font-size: 42%;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #005677;
}

body {
  font-family: $wellington;
  font-size: 18px;
  color: $dark-grey-blue;
}