$wellington: 'TT Wellingtons';

$dark-grey-blue: #25344b;
$color-orange: #ffa400;

$color-grey: #eaeaea;
$color-light-grey: #f1f3f8;
$color-dark-grey: #524b4c;
$color-light-grey-2: #f8f9fb;

