.page-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 60px 120px 20px;
    flex-wrap: wrap;

    @include max-screen(1024px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99999;
      opacity: 0.98;
      padding: 0;
    }
  }

  .nav {
    @include max-screen(1024px) {
      transform: translateY(-100%);
      width: 100%;
      padding: 20px;
      transition: .4s;
    }

    &.active {
      background: #fff;
      z-index: 1;
      transform: translateY(0);
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;

      @include max-screen(1024px) {
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        margin-bottom: 5rem;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: 4rem;

        @include max-screen(1024px) {
          margin-right: 0;
        }
      }

      @include max-screen(1024px) {
        flex-direction: column;
        align-items: center;

        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    &__link {

      @include max-screen(1024px) {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        color: $dark-grey-blue;
      }
    }
  }
}
