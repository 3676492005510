.event-block {
  width: calc(33.3% - 40px);
  float: left;
  margin: 7rem 20px 0;
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
  text-align: left;
  @include max-screen(1024px) {
    width: calc(50% - 80px);
    margin: 7rem 35px 0;
    float: none;
    display: inline-block;
  }
  @include max-screen(768px) {
    margin: 4rem 20px 0;
    width: 320px;
  }
  .event-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .img-wrapper {
    padding-bottom: 75%;
  }
  .text-part {
    padding: 2.8rem 4rem 2.5rem;
    @include max-screen(768px) {
      padding: 32px;
    }
  }

  h3 + p {
    margin-top: 1.2rem;
  }
}