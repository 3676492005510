.hero-wrapper {
  position: relative;

  .absolute-centered {
    @include max-screen(1024px) {
      width: 100%;
      padding: 120px 20px 0;
    }
  }

  &.smaller {
    padding-bottom: 30%;
    @include max-screen(1024px) {
      min-height: 480px;
    }
    .text-center {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 60px;
    }
  }

  h1 + p {
    margin-top: 4rem;
  }

  p + .btn {
    margin-top: 4rem;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.27;
    background-blend-mode: multiply;
    background-image: linear-gradient(#25344b, #25344b);
  }
}