.logo {
  width: 18rem;

  @include max-screen(1199px) {
    width: 140px;
  }

  @include max-screen(1024px) {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);

  }

  &__img {
    display: block;
  }
}
