.theme-section {
  .container {
    position: relative;
    height: inherit;
  }
  .text-block {
    right: 24px;
    position: absolute;
    bottom: 10%;
    width: 50%;
    padding: 86px 80px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
    @include max-screen(1024px) {
      padding: 40px 80px;
    }
    @include max-screen(768px) {
      padding: 40px;
      width: 100%;
      bottom: 40px;
      max-width: 480px;
    }
    p {
      margin-top: 3.5rem;
    }

    h4 {
      margin-top: 1.8rem;
      font-weight: 500;
      a {
        color: #707785;
      }
    }
  }
}