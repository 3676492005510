.footer {
  padding: 60px 0 73px;
  background-image: linear-gradient(108deg, #f2f3f4, #e1e3e6);
  @include max-screen(1024px) {
    padding: 40px 0 60px;
  }
  @include max-screen(768px) {
    padding: 1px 0 40px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    @include max-screen(768px) {
      display: block;
    }
  }
  .footer-block {
    @include max-screen(768px) {
      margin-top: 40px;
    }
  }
  ul {
    margin-top: 12px;
  }
  li {
    margin-top: 8px;
  }
  .address-block {
    li,
    a {
      color: #25344b;
    }
  }

  .social-block {
    li {
      float: left;
      width: 50%;
      @include max-screen(768px) {
        width: auto;
        margin-right: 19px;
      }
    }
  }
  @include max-screen(1024px) {
    .text-small {
      font-size: 12px;
    }
  }
}
