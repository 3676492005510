.download-section {
  background-color: rgb(243, 247, 249);
  padding: 8rem 0;
  .tile {
    display: inline-block;
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
    padding: 24px 24px 24px 96px;
    box-sizing: border-box;
    max-width: 360px;
    text-align: left;
    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    img {
      position: absolute;
      top: 24px;
      left: 28px;
    }

    span {
      display: inline-block;
      margin-top: 4px;
      opacity: 0.8;
      color: #005678;
    }
  }

}