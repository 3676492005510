.menu-btn {
  position: relative;
  border: none;
  outline: none;
  background: none;
  width: 24px;
  height: 24px;
  margin-bottom: -10px;
  transition: margin 0.3s ease;
  z-index: 3;
  @include max-screen(1024px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &:focus {
    outline: none;
  }
}

.sandwich {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  right: 0;
  display: none;

  @include max-screen(1024px) {
    display: block;
  }

  &__top,
  &__bottom,
  &__center {
    position: relative;
    width: 24px;
    height: 2px;
    background-color: #fff;
    border: none;
    border-radius: 4px 4px 4px 4px;
  }

  &__top {
    top: 0px;
    transition: transform 0.5s, top 0.2s;
    transition-delay: 0.2s, 0s;
  }

  &__center {
    top: 6px;
    transition: transform 0.5s, top 0.2s;
    transition-delay: 0.2s, 0s;
  }

  &__bottom {
    top: 12px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
}

.menu-btn--active {
  margin-bottom: 0;

  .sandwich__top {
    top: 11px;
    transform: rotate(-45deg);
    background-color: #000;
  }

  .sandwich__center {
    top: 9px;
    transform: rotate(45deg);
    background-color: #000;
  }

  .sandwich__bottom {
    opacity: 0;
    top: 0;
    transform: rotate(180deg);
    background-color: #000;
  }
}
