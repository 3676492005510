h1 {
  font-size: 6.4rem;
}

h2 {
  font-size: 5.2rem;
}

h3 {
  font-size: 3.2rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2rem;
}
.text-bigger {
  font-size: 2.8rem;
}
.text-large {
  font-size: 18px;
}

.text-small {
  font-size: 16px;
}

.text-xsmall {
  font-size: 12px;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

.text-regular {
  font-weight: 400;
}

.text-semibold {
  font-weight: 600;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}