.text-snow {
  color: #fff;
}

.text-grey {
  color: rgba(20,20,20,0.24);
}

.text-darkgrey {
  color: rgb(112, 119, 133);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-fix:after {
  content: "";
  clear: both;
  display: table;
}

.vertically-centered {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic-overlay {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.arrow-link {
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    background: url(/img/icn-more.svg) no-repeat center;
    margin-left: 9px;
    vertical-align: top;
    display: inline-block;
  }
}

hr {
  border-width: 2px 0 0;
  border-color: #e1e3e6;
  margin: 2rem 0;
  outline: 0;
  border-style: solid;
}