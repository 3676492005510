.container {
  max-width: 1198px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.big-container {
  max-width: 1320px;
  @extend .container;
}
