@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-BoldItalic.eot');
  src: url('/fonts/TTWellingtons-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-BoldItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-BoldItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-BoldItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-BoldItalic.svg#TTWellingtons-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Black.eot');
  src: url('/fonts/TTWellingtons-Black.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Black.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Black.woff') format('woff'),
  url('/fonts/TTWellingtons-Black.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Black.svg#TTWellingtons-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-ExtraLightItalic.eot');
  src: url('/fonts/TTWellingtons-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-ExtraLightItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-ExtraLightItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-ExtraLightItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-ExtraLightItalic.svg#TTWellingtons-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-BlackItalic.eot');
  src: url('/fonts/TTWellingtons-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-BlackItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-BlackItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-BlackItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-BlackItalic.svg#TTWellingtons-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-ExtraBold.eot');
  src: url('/fonts/TTWellingtons-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-ExtraBold.woff2') format('woff2'),
  url('/fonts/TTWellingtons-ExtraBold.woff') format('woff'),
  url('/fonts/TTWellingtons-ExtraBold.ttf') format('truetype'),
  url('/fonts/TTWellingtons-ExtraBold.svg#TTWellingtons-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-MediumItalic.eot');
  src: url('/fonts/TTWellingtons-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-MediumItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-MediumItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-MediumItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-MediumItalic.svg#TTWellingtons-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Thin.eot');
  src: url('/fonts/TTWellingtons-Thin.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Thin.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Thin.woff') format('woff'),
  url('/fonts/TTWellingtons-Thin.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Thin.svg#TTWellingtons-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-DemiBold.eot');
  src: url('/fonts/TTWellingtons-DemiBold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-DemiBold.woff2') format('woff2'),
  url('/fonts/TTWellingtons-DemiBold.woff') format('woff'),
  url('/fonts/TTWellingtons-DemiBold.ttf') format('truetype'),
  url('/fonts/TTWellingtons-DemiBold.svg#TTWellingtons-DemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Medium.eot');
  src: url('/fonts/TTWellingtons-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Medium.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Medium.woff') format('woff'),
  url('/fonts/TTWellingtons-Medium.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Medium.svg#TTWellingtons-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-LightItalic.eot');
  src: url('/fonts/TTWellingtons-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-LightItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-LightItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-LightItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-LightItalic.svg#TTWellingtons-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-DemiBoldItalic.eot');
  src: url('/fonts/TTWellingtons-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-DemiBoldItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-DemiBoldItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-DemiBoldItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-DemiBoldItalic.svg#TTWellingtons-DemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-ExtraLight.eot');
  src: url('/fonts/TTWellingtons-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-ExtraLight.woff2') format('woff2'),
  url('/fonts/TTWellingtons-ExtraLight.woff') format('woff'),
  url('/fonts/TTWellingtons-ExtraLight.ttf') format('truetype'),
  url('/fonts/TTWellingtons-ExtraLight.svg#TTWellingtons-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Light.eot');
  src: url('/fonts/TTWellingtons-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Light.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Light.woff') format('woff'),
  url('/fonts/TTWellingtons-Light.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Light.svg#TTWellingtons-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Regular.eot');
  src: url('/fonts/TTWellingtons-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Regular.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Regular.woff') format('woff'),
  url('/fonts/TTWellingtons-Regular.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Regular.svg#TTWellingtons-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Italic.eot');
  src: url('/fonts/TTWellingtons-Italic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Italic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Italic.woff') format('woff'),
  url('/fonts/TTWellingtons-Italic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Italic.svg#TTWellingtons-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-Bold.eot');
  src: url('/fonts/TTWellingtons-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-Bold.woff2') format('woff2'),
  url('/fonts/TTWellingtons-Bold.woff') format('woff'),
  url('/fonts/TTWellingtons-Bold.ttf') format('truetype'),
  url('/fonts/TTWellingtons-Bold.svg#TTWellingtons-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-ExtraBoldItalic.eot');
  src: url('/fonts/TTWellingtons-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-ExtraBoldItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-ExtraBoldItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-ExtraBoldItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-ExtraBoldItalic.svg#TTWellingtons-ExtraBoldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('/fonts/TTWellingtons-ThinItalic.eot');
  src: url('/fonts/TTWellingtons-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/TTWellingtons-ThinItalic.woff2') format('woff2'),
  url('/fonts/TTWellingtons-ThinItalic.woff') format('woff'),
  url('/fonts/TTWellingtons-ThinItalic.ttf') format('truetype'),
  url('/fonts/TTWellingtons-ThinItalic.svg#TTWellingtons-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
}

