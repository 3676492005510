.main-text-block {
  max-width: 720px;
  padding: 8rem 0 11rem;
  margin: 0 auto;
  @include max-screen(1024px) {
    padding: 5rem 0 7rem;
  }
  p {
    margin-top: 3rem;
  }

  .btn {
    margin-top: 3rem;
    font-weight: 500;
  }

  &.smaller-margins {
    padding: 8rem 0 5rem;
    @include max-screen(1024px) {
      padding: 5rem 0 4rem;
    }
  }
}