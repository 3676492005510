.blog-page {
  .filter-wrapper {
    .big-container {
      display: table;
      @include max-screen(1024px) {
        display: block;
        margin-top: 40px;
      }
      .table-cell {
        display: table-cell;
        vertical-align: middle;
        @include max-screen(1024px) {
          display: block;
          text-align: left;
          overflow: hidden;
        }
      }
      .search-container {
        margin: 0 20px;
        @include max-screen(1024px) {
          margin: 20px 0 0;
        }
      }
      .checkbox-wrapper {
        display: inline-block;
        padding-left: 22px;
        box-sizing: border-box;
        @include max-screen(1024px) {
          width: calc(100% - 70px);
          white-space: nowrap;
          overflow: scroll;
          vertical-align: middle;
        }
        .check-container {
          margin-left: 8px;
        }
      }
    }
  }

  .small-filter-wrapper {
    .custom-select {
      width: 70px;
    }
    .table {
      max-width: 864px;
      width: 100%;
      margin: 38px auto;
      display: table;
      @include max-screen(768px) {
        display: block;
      }
      .table-cell {
        display: table-cell;
        vertical-align: middle;
        @include max-screen(768px) {
          display: block;
          text-align: center;
        }
      }
    }
    .select-label {
      opacity: 0.8;
    }
  }

  .pagination-container {
    margin: 4rem 0 10rem;
  }
}