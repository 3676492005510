.partners-wrapper {
  padding: 6.6rem 0;
  .partners-list {
    margin-top: 6.7rem;
    display: flex;
    justify-content: space-around;
    @include max-screen(768px) {
      display: block;
      text-align: center;
    }
    .partner-container {
      width: 20%;
      vertical-align: middle;
      display: inline-block;
      @include max-screen(768px) {
        width: 32%;
      }
    }
  }
}

.map-section {
  .weather {
    position: absolute;
    left: 15%;
    top: 84%;
  }
  .container  {
    padding: 6.5rem 20px;
    background-position-x: right;
    background-position-y: 6.5rem;
    background-repeat: no-repeat;
    @include max-screen(768px) {
      background-size: 50%;
    }
  }
  .section-text-block {
    padding-bottom: 15rem;
  }
  .map-text-block {
    width: calc(48% + 120px);
    padding: 16.7rem 0 18.7rem;

    @include max-screen(768px) {
      width: 100%;
      padding: 74px 0;
      max-width: 480px;
      margin: 0 auto;
    }

    p {
      margin-top: 4.8rem;
      max-width: 50.6rem;
    }
  }
}

.section-text-block {
  position: relative;
  @include max-screen(768px) {
    max-width: 480px;
    margin: 0 auto;
  }
  &.with-paddings {
    margin: 120px 0 0;
    @include max-screen(768px) {
      margin: 60px auto 0;
    }
  }
  .img-block {
    width: 50%;
    position: relative;
    z-index: 2;
    padding-bottom: 40%;
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.25);
    @include max-screen(768px) {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      box-shadow: none;
    }
  }
  .text-block {
    margin-left: -120px;
    margin-top: 15%;
    width: calc(48% + 120px);
    padding: 86px 80px 80px 200px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);

    @include max-screen(1199px) {
      padding: 60px 80px 60px 160px;
      margin-left: -80px;
      width: calc(48% + 80px);
    }

    @include max-screen(768px) {
      margin-left: 0;
      width: 100%;
      padding: 73px 40px 40px;
      margin-top: -30px;
    }

    p {
      margin-top: 3.5rem;
    }

    h4 {
      margin-top: 1.8rem;
      font-weight: 500;
      a {
        color: #707785;
      }
    }
  }
  &.img-on-right {
    .text-block {
      padding-left: 80px;
      padding-right: 200px;
      margin-right: -120px;
      margin-left: 0;
      @include max-screen(1199px) {
        padding-left: 50px;
        margin-right: -80px;
        padding-right: 160px;

      }
      @include max-screen(768px) {
        padding: 73px 40px 40px;
        margin-right: 0;
      }
    }
  }
}

.contact-wrapper {
  max-width: 740px;
  margin: 8rem auto 0;
  .btn {
    width: 144px;
  }
}

.event-section {
  .slider-section {
    position: relative;
  }
  .big-slider {
    max-width: 720px;
    margin: 0 auto;
    @include max-screen(1199px) {
      max-width: 680px;
    }
    .big-slide {
      width: 100%;
      background-size: cover;
      background-position: center;
      height: 540px;
      @include max-screen(1199px) {
        height: 511px;
      }
      @include max-screen(768px) {
        height: 400px;
      }
    }
  }
  .nav-slider {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    .nav-slide {
      position: relative;
      background-size: cover;
      background-position: center;
      margin: 0 20px;
      height: 120px;
      width: 160px;
      @include max-screen(1199px) {
        width: 140px;
        height: 105px;
      }
      @include max-screen(768px) {
        width: 105px;
        height: 78px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0.32;
        background-blend-mode: color;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
      }
    }
  }
  .event-info {
    margin-top: 45px;
    h2 {
      line-height: 1;
      vertical-align: top;
      display: inline-block;
      width: auto;
      @include max-screen(768px) {
        width: 100%;
      }
    }
    p {
      margin-top: 5px;
      width: calc(100% - 160px);
      vertical-align: top;
      padding-left: 80px;
      box-sizing: border-box;
      @include max-screen(768px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}
