
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.check-container {
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: solid 2px transparent;
}
.check-container input:checked ~ .checkmark {
  border-color: rgb(249, 162, 27);
}