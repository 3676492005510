.explore-page {
  .theme-section {
    height: 100vh;
  }

  .filter-wrapper {
    width: 320px;
    position: absolute;
    top: 50%;
    background: #fff;
    left: 0;
    transform: translateY(-50%);
    padding: 4rem;
    box-sizing: border-box;
    @include max-screen(768px) {
      position: relative;
      top: 0;
      transform: translateY(0);
      margin: 0 auto 4rem;
    }

    .checkbox-wrapper {
      margin-top: 1.2rem;
      .check-container {
        margin-bottom: 2.4rem;
        width: 100%;
        padding: 17px 20px 18px;
        .checkmark {
          border-color: #e1e3e6;
        }
      }
    }

    .check-container input:checked ~ .checkmark {
      border-color: #f9a21b;
    }

    .location-ico {
      right: 16px;
      left: auto;
      width: 24px;
      height: 24px;
    }
  }

  .map-section {
    padding-bottom: 60%;
    position: relative;

    #map {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .events-wrapper {
    margin-bottom: 12rem;
    @include max-screen(1024px) {
      text-align: center;
    }
    .event-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom:0;
    }
  }
  .btn-wrapper {
    margin-top: 6rem;
  }
}
