.btn {
  display: inline-block;
  text-decoration: none;
  padding: 14px 32px 12px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 8px 16px 0 rgba(255, 164, 0, 0.16);
  transition: all 0.2s ease;
  cursor: pointer;
  color: #fff;
  background: $color-orange;
  border: 0;
  text-align: center;

  &.white {
    box-shadow: none;
    background: #fff;
    color: $dark-grey-blue;
    text-transform: capitalize;
  }
}
.down-button {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 12px 24px 0 rgba(37, 52, 75, 0.16);
  position: fixed;
  bottom:0;
  transform: translate(-50%, 0);
  left: 50%;
  z-index: 10;
  border-radius: 50%;
  @include max-screen(1024px) {
    display: none;
  }
}

.fp-viewing-9 .down-button {
  display: none;
}